<template>
  <ul>
    <div
      class="pl-2 pr-2"
      style="margin-top:140px;"
    >
      <p
        class="text-center m-0 text-warning"
        v-text="currentTime"
      />
      <div
        v-if="UserData.role !== 'member'"
        class="d-flex justify-content-between"
      >
        <small>Username</small>
        <small>{{ UserData.agent_name ? UserData.agent_name : UserData.name ? UserData.name :'-' }}</small>
      </div>
      <div
        v-else
        class="d-flex justify-content-between"
      >
        <small>Username</small>
        <small>{{ UserData.username ? UserData.username : '-' }}</small>
      </div>
      <div
        v-if="UserData.role === 'member'"
        class="d-flex justify-content-between"
      >
        <small>Name</small>
        <small>{{ UserData.name ? UserData.name + ' ' + UserData.surname : '-' }}</small>
      </div>
      <div class="d-flex justify-content-between">
        <small>Balance</small>
        <!-- {{ AgentData }} -->
        <small>{{ AgentData ? Commas(AgentData.balance.toFixed(2)) : Commas(userbalance.toFixed(2)) }} </small>
      </div>
      <div
        v-if="UserData.role !== 'member'"
        class="d-flex justify-content-between"
      >
        <small>Member</small>
        <small>{{ AgentData.member ? AgentData.member : 0 }}</small>
      </div>
      <!-- <div class="d-flex justify-content-between">
        <small>Online</small>
        <small>0</small>
      </div> -->
    </div>
    <hr>
    <component
      :is="resolveNavItemComponent(item)"
      v-for="item in items"
      :key="item.header || item.title"
      :item="item"
    />
  </ul>
</template>

<script>
import { resolveVerticalNavMenuItemComponent as resolveNavItemComponent } from '@core/layouts/utils'
import { provide, ref } from '@vue/composition-api'
import moment from 'moment-timezone'
import VerticalNavMenuHeader from '../vertical-nav-menu-header'
import VerticalNavMenuLink from '../vertical-nav-menu-link/VerticalNavMenuLink.vue'
import VerticalNavMenuGroup from '../vertical-nav-menu-group/VerticalNavMenuGroup.vue'

export default {
  components: {
    VerticalNavMenuHeader,
    VerticalNavMenuLink,
    VerticalNavMenuGroup,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      UserData: JSON.parse(localStorage.getItem('userData')),
      currentTime: null,
      AgentData: null,
      userbalance: 0.00,
    }
  },
  setup() {
    provide('openGroups', ref([]))

    return {
      resolveNavItemComponent,
    }
  },
  beforeDestroy() {
    clearInterval(this.Interval)
  },
  // eslint-disable-next-line no-dupe-keys
  created() {
    this.currentTime = moment().format('LTS')
    setInterval(() => this.updateCurrentTime(), 1 * 1000)
    this.Interval = setInterval(() => {
      this.GetData()
    }, 60000)
  },
  mounted() {
    this.GetData()
  },
  methods: {
    async ShowWallet() {
      try {
        const params = {
          UserToken: localStorage.getItem('UserToken'),
        }
        const { data: response } = await this.$http.get(
          'seamless/wallet/show', { params },
        )
        if (response && response.success === true) {
          const Main = response.WalletData
          this.userbalance = Main.balance
        } else {
          this.userbalance = 0
        }
      } catch (err) {
        console.log(err)
      }
    },
    GetData() {
      if (this.UserData.role === 'member') {
        this.ShowWallet()
        this.$http
          .get('member/agent/GetMenuData')
          .then(response => {
            this.AgentData = response.data
          })
          .catch(error => console.log(error))
      } else {
        this.$http
          .get('agent/GetMenuData')
          .then(response => {
            this.AgentData = response.data
          })
          .catch(error => console.log(error))
      }
    },
    updateCurrentTime() {
      this.currentTime = moment(new Date()).tz('Asia/Bangkok').format('DD-MMMM-YYYY HH:mm:ss')
    },
    Commas(x) {
      if (Math.round(x) !== x && x > 0) {
        const Num = Number(x)
        const y = Num.toFixed(2)
        return y.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      }
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
  },
}
</script>

<style scoped>
p {
  font-size: 12px;
}
</style>
